import React from "react";
import { Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import Container from "react-bootstrap/Container";

export default function LeftNav(props) {
  return (
    <Container className="leftnav">
      <Row>
        <p className="leftnav-label">Products</p>
        <NavLink to="/" className="leftnav-link">
          <div>Home</div>
        </NavLink>
        <NavLink to="/product_list#womens" className="leftnav-link">
          <div>Women's Clotting</div>
        </NavLink>
        <NavLink to="/product_list#girls" className="leftnav-link">
          <div>Girl's Clothing</div>
        </NavLink>
        <NavLink to="/product_list#mens" className="leftnav-link">
          <div>Men's/Boy's Clothing</div>
        </NavLink>
        <NavLink to="/product_list#household" className="leftnav-link">
          <div>Household Items</div>
        </NavLink>
        <p className="leftnav-label extra-top-space">More info</p>
        <NavLink to="/contact" className="leftnav-link">
          <div>Contact us</div>
        </NavLink>
        <NavLink to="/faq" className="leftnav-link">
          <div>FAQ</div>
        </NavLink>
      </Row>
    </Container>
  );
}
