
const data = {
    products:
[
    {
        "brand": "BCBGMAXAZRIA",
        "condition": "NWT",
        "price": 30,
        "size": "XS",
        "color": "red/sand/stripes",
        "material": "55% Linen/45% Viscose",
        "description": "Super chic cropped knit sweater top with ribbed hem. Red and light sand stripes. Longer in the back. Drapes really nice.",
        "pictures": [
            "BCBG_top_striped_sweater.jpg",
            "BCBG_top_striped_sweater2.jpg",
            "BCBG_top_striped_sweater3.jpg",
            "BCBG_top_striped_sweater4.jpg"
        ],
        "measurement": "",
        "originalRetail": 158,
        "itemNumber": "B0101",
        "name": "BCBG Striped Cropped Sweater Top",
        "mainCategory": "Women's Clothing",
        "subCategory": "Top",
        "id": 1
    },
    {
        "brand": "Halogen by Nordstrom",
        "condition": "NWT",
        "price": 20,
        "size": "S",
        "color": "Grey",
        "material": "40%Nylon/30%Viscose/30%Wool",
        "description": "Not just another boring grey cardigan! This wool blend cardigan has a belt closure for a more relaxed fit.  3/4 length sleeves. Beautiful rhinestones adorned the front and the top of the two pockets. Chiffon edge adds elegance.",
        "pictures": [
            "Halogen_cardigan_grey_rhinestone.jpg",
            "Halogen_cardigan_grey_rhinestone2.jpg",
            "Halogen_cardigan_grey_rhinestone3.jpg",
            "Halogen_cardigan_grey_rhinestone4.jpg"
        ],
        "measurement": "",
        "originalRetail": 98,
        "itemNumber": "B0102",
        "name": "Halogen Rhinestone Grey Sweater Cardigan",
        "mainCategory": "Women's Clothing",
        "subCategory": "Jacket/Outerwear",
        "id": 2
    },
    {
        "brand": "Trouve from Nordstrom",
        "condition": "NWT",
        "price": 18,
        "size": "S",
        "color": "Drak brown/brown glimmer",
        "material": "86% Acrylic/14%Nylon",
        "description": "Stylish chunky knit chocolate brown sweater with a subtle glimmer all over. Very soft and hangs very nice on body.",
        "pictures": [
            "Trouve_brown_sparkly_sweater.jpg",
            "Trouve_brown_sparkly_sweater2.jpg",
            "Trouve_brown_sparkly_sweater3.jpg",
            "Trouve_brown_sparkly_sweater4.jpg"
        ],
        "measurement": "",
        "originalRetail": 88,
        "itemNumber": "B0103",
        "name": "Trouve Brown Knit Sweater Top with brown Glimmer",
        "mainCategory": "Women's Clothing",
        "subCategory": "Top",
        "id": 3
    },
    {
        "brand": "Ten Sixty Sherman",
        "condition": "NWT",
        "price": 8,
        "size": "L",
        "color": "Sage green",
        "material": "65%Polyester/35%Rayon",
        "description": "Casual long sleeve knit top with button and white lace details. Super soft and comfortable.",
        "pictures": [
            "Tensixty_sherman_top_LS_green_lace.jpg",
            "Tensixty_sherman_top_LS_green_lace2.jpg",
            "Tensixty_sherman_top_LS_green_lace3.jpg"
        ],
        "measurement": "",
        "originalRetail": 32,
        "itemNumber": "B0104",
        "name": "Ten Sixty Sherman Long Sleeve Knit Top",
        "mainCategory": "Women's Clothing",
        "subCategory": "Top",
        "id": 4
    },
    {
        "brand": "Ten Sixty Sherman",
        "condition": "NWT",
        "price": 8,
        "size": "M",
        "color": "Ivory",
        "material": "65%Polyester/35%Rayon",
        "description": "Casual long sleeve knit top with button and white lace details. Super soft and comfortable.",
        "pictures": [
            "Tensixty_sherman_top_LS_white_lace.jpg",
            "Tensixty_sherman_top_LS_white_lace2.jpg",
            "Tensixty_sherman_top_LS_white_lace3.jpg"
        ],
        "measurement": "",
        "originalRetail": 32,
        "itemNumber": "B0105",
        "name": "Ten Sixty Sherman Long Sleeve Knit Top",
        "mainCategory": "Women's Clothing",
        "subCategory": "Top",
        "id": 5
    },
    {
        "brand": "Caslon by Nordstrom",
        "condition": "NWT",
        "price": 10,
        "size": "S",
        "color": "Covert green",
        "material": "100% cotton",
        "description": "Elegant lightweight cardigan with ruffle trim open front.",
        "pictures": [
            "caslon_ruffle_cardigan_green.jpg",
            "caslon_ruffle_cardigan_green2.jpg",
            "caslon_ruffle_cardigan_green3.jpg",
            "caslon_ruffle_cardigan_green4.jpg"
        ],
        "measurement": "",
        "originalRetail": 48,
        "itemNumber": "B0106",
        "name": "Caslon Ruffle Front Cardigan",
        "mainCategory": "Women's Clothing",
        "subCategory": "Jacket/Outerwear",
        "id": 6
    },
    {
        "brand": "West Kei",
        "condition": "NWT",
        "price": 8,
        "size": "XS",
        "color": "Lilac",
        "material": "90% Rayon/10% Polyester",
        "description": "Beautiful sheer top with button ruched details on the left side for added style.",
        "pictures": [
            "west_kei_sheer_top_lilac_xs.jpg",
            "west_kei_sheer_top_lilac_xs2.jpg",
            "west_kei_sheer_top_lilac_xs3.jpg"
        ],
        "measurement": "",
        "originalRetail": 32,
        "itemNumber": "B0107",
        "name": "West Kei Side Button Ruched Tunic",
        "mainCategory": "Women's Clothing",
        "subCategory": "Top",
        "id": 7
    },
    {
        "brand": "Gap",
        "condition": "NWT",
        "price": 12,
        "size": "M",
        "color": "Ivory cream",
        "material": "80% Cotton/10% Silk/10% Modal",
        "description": "A versatile piece that goes well with everything! Perfect to take the chill out at cool summer nights.",
        "pictures": [
            "gap_open_front_cardigan_cream_M.jpg",
            "gap_open_front_cardigan_cream_M2.jpg",
            "gap_open_front_cardigan_cream_M3.jpg"
        ],
        "measurement": "",
        "originalRetail": 48,
        "itemNumber": "B0108",
        "name": "Gap Open Front Short Sleeve Cardigan",
        "mainCategory": "Women's Clothing",
        "subCategory": "Jacket/Outerwear",
        "id": 8
    },
    {
        "brand": "Rubbish from Nordstrom",
        "condition": "NWOT",
        "price": 10,
        "size": "LJ",
        "color": "Red-Rococco",
        "material": "60% Cotton/20% Viscose/20% Nylon",
        "description": "Brown suede patch on elbows adding an equestrian twist to this classic v-neck sweater.",
        "pictures": [
            "rubbish_elbow_patch_sweater_red_L.jpg",
            "rubbish_elbow_patch_sweater_red_L2.jpg",
            "rubbish_elbow_patch_sweater_red_L3.jpg"
        ],
        "measurement": "",
        "originalRetail": 48,
        "itemNumber": "B0109",
        "name": "Rubbish Elbow Patch Sweater",
        "mainCategory": "Women's Clothing",
        "subCategory": "Sweater",
        "id": 9
    },
    {
        "brand": "Gap",
        "condition": "NWOT",
        "price": 12,
        "size": "M",
        "color": "Purple/magenta",
        "material": "70% Cotton/20% Nylon/10% Silk",
        "description": "Deep v-neck sweater with button trim. Soft and lightweight.",
        "pictures": [
            "gap_button_sweater_purple_M.jpg",
            "gap_button_sweater_purple_M2.jpg",
            "gap_button_sweater_purple_M3.jpg"
        ],
        "measurement": "",
        "originalRetail": 49.95,
        "itemNumber": "B0110",
        "name": "Gap Buttoned V-neck Sweater",
        "mainCategory": "Women's Clothing",
        "subCategory": "Sweater",
        "id": 10
    },
    {
        "brand": "Gap",
        "condition": "NWT",
        "price": 12,
        "size": "M",
        "color": "Pink",
        "material": "70% Cotton/20% Nylon/10% Silk",
        "description": "Deep v-neck sweater with button trim. Soft and lightweight.",
        "pictures": [
            "gap_button_sweater_pink_M.jpg",
            "gap_button_sweater_pink_M2.jpg",
            "gap_button_sweater_pink_M3.jpg"
        ],
        "measurement": "",
        "originalRetail": 49.95,
        "itemNumber": "B0111",
        "name": "Gap Buttoned V-neck Sweater",
        "mainCategory": "Women's Clothing",
        "subCategory": "Sweater",
        "id": 11
    },
    {
        "brand": "Bobeau at Nordstrom",
        "condition": "NWT",
        "price": 8,
        "size": "S",
        "color": "Hot pink",
        "material": "96% Rayon/4% Spandex",
        "description": "Classic v-neck top in an eye-catching hot pink color!",
        "pictures": [
            "Bobeau_SS_top_hotpink_S.jpg",
            "Bobeau_SS_top_hotpink_S2.jpg",
            "Bobeau_SS_top_hotpink_S3.jpg"
        ],
        "measurement": "",
        "originalRetail": 32,
        "itemNumber": "B0112",
        "name": "Bobeau V-neck Top",
        "mainCategory": "Women's Clothing",
        "subCategory": "Top",
        "id": 12
    },
    {
        "brand": "Gap",
        "condition": "NWOT",
        "price": 8,
        "size": "M",
        "color": "Orange",
        "material": "100% cotton",
        "description": "Soft round neck cotton long sleeve knit top with a cute little pocket accent.",
        "pictures": [
            "gap_LS_knit_top_orange_M.jpg",
            "gap_LS_knit_top_orange_M2.jpg",
            "gap_LS_knit_top_orange_M3.jpg"
        ],
        "measurement": "",
        "originalRetail": null,
        "itemNumber": "B0113",
        "name": "Gap Round Neck Long Sleeve Knit Top",
        "mainCategory": "Women's Clothing",
        "subCategory": "Top",
        "id": 13
    },
    {
        "brand": "Halogen by Nordstrom",
        "condition": "NWT",
        "price": 15,
        "size": "S",
        "color": "Ginger Pink",
        "material": "50% Merino wool/50% Acrylic",
        "description": "Button front lightweight cardigan.  The color is a beautiful light coral pink.",
        "pictures": [
            "halogen_button_LS_cardigan_coral.jpg",
            "halogen_button_LS_cardigan_coral2.jpg",
            "halogen_button_LS_cardigan_coral3.jpg",
            "halogen_button_LS_cardigan_coral4.jpg"
        ],
        "measurement": "",
        "originalRetail": 59,
        "itemNumber": "B0114",
        "name": "Halogen Long-Sleeve Boyfriend Cardigan",
        "mainCategory": "Women's Clothing",
        "subCategory": "Jacket/Outerwear",
        "id": 14
    },
    {
        "brand": "Caslon by Nordstrom",
        "condition": "NWOT",
        "price": 15,
        "size": "XS",
        "color": "Black/Heather Shadow",
        "material": "100% cotton",
        "description": "This trendy cardigan has a nice weight and drapes beautifully! It will be a staple piece in your wardrobe.",
        "pictures": [
            "caslon_open_front_cardigan_grey_stripe_XS.jpg",
            "caslon_open_front_cardigan_grey_stripe_XS2.jpg",
            "caslon_open_front_cardigan_grey_stripe_XS3.jpg"
        ],
        "measurement": "",
        "originalRetail": 58,
        "itemNumber": "B0115",
        "name": "Caslon Stripe Cardigan",
        "mainCategory": "Women's Clothing",
        "subCategory": "Jacket/Outerwear",
        "id": 15
    },
    {
        "brand": "Payton at Nordstrom",
        "condition": "NWT",
        "price": 25,
        "size": "S",
        "color": "Black  ",
        "material": "70% Polyester/30% Mohair",
        "description": "Stylish sequined dolman sleeves sweater with subtle sparkle that lights up your day!",
        "pictures": [
            "payton_sequined_sweater_black_S.jpg",
            "payton_sequined_sweater_black_S2.jpg",
            "payton_sequined_sweater_black_S3.jpg"
        ],
        "measurement": "",
        "originalRetail": null,
        "itemNumber": "B0116",
        "name": "Payton Sparkle Dolman Sleeve Sweater",
        "mainCategory": "Women's Clothing",
        "subCategory": "Sweater",
        "id": 16
    },
    {
        "brand": "Rubbish from Nordstrom",
        "condition": "NWOT",
        "price": 12,
        "size": "M",
        "color": "Turquoise",
        "material": "100% cotton",
        "description": "The picture doesn't do it justice! This beautiful sweater dress looks as good as it feels with 100% soft cotton.",
        "pictures": [
            "rubbish_sweater_dress_turquoise_M1.jpg",
            "rubbish_sweater_dress_turquoise_M2.jpg",
            "rubbish_sweater_dress_turquoise_M3.jpg",
            "rubbish_sweater_dress_turquoise_M4.jpg"
        ],
        "measurement": "",
        "originalRetail": 49,
        "itemNumber": "B0117",
        "name": "Rubbish Cable Knit Tank Sweater Dress",
        "mainCategory": "Women's Clothing",
        "subCategory": "Sweater/Dress",
        "id": 17
    },
    {
        "brand": "Caslon by Nordstrom",
        "condition": "NWOT",
        "price": 12,
        "size": "S",
        "color": "Rumba Red/Black",
        "material": "55% Cotton/25% Modal/20% Polyester",
        "description": "Soft colorblock sweater that fits loose but not boxy. Drape nack, ribbed sleeve and curved hem, the style is all in the small details.",
        "pictures": [
            "caslon_drapeneck_colorblock_sweater_S.jpg",
            "caslon_drapeneck_colorblock_sweater_S2.jpg",
            "caslon_drapeneck_colorblock_sweater_S3.jpg"
        ],
        "measurement": "",
        "originalRetail": 58,
        "itemNumber": "B0118",
        "name": "Caslon Colorbloack Drape Neck Sweater",
        "mainCategory": "Women's Clothing",
        "subCategory": "Sweater",
        "id": 18
    },
    {
        "brand": "Lucky Brand",
        "condition": "NWT",
        "price": 40,
        "size": "S",
        "color": "Natural Multi",
        "material": "34% Wool/33% Acrylic/28% Nylon/5% Alpaca",
        "description": "Stunning open-front belted sweater cardigan with zigzag pattern in neutral hues. Scalloped hem and eyelet details adds an exquisite touch.",
        "pictures": [
            "lucky_brand_zigzag_sweater_cardigan_S.jpg",
            "lucky_brand_zigzag_sweater_cardigan_S2.jpg",
            "lucky_brand_zigzag_sweater_cardigan_S3.jpg",
            "lucky_brand_zigzag_sweater_cardigan_S4.jpg",
            "lucky_brand_zigzag_sweater_cardigan_S5.jpg"
        ],
        "measurement": "",
        "originalRetail": 149,
        "itemNumber": "B0119",
        "name": "Lucky Brand Zigzag Long Sweater Cardigan",
        "mainCategory": "Women's Clothing",
        "subCategory": "Jacket/Outerwear",
        "id": 19
    },
    {
        "brand": "Gap",
        "condition": "NWT",
        "price": 15,
        "size": "25/0",
        "color": "Dark Wash",
        "material": "98% Cotton/2% Spandex",
        "description": "Classic 5-pocket Gap 1969 forever skinny dark wash jeans. Button-closure, zip-fly. Mid rise.",
        "pictures": [
            "gap1969_forever_skinny_jeans.jpg",
            "gap1969_forever_skinny_jeans2.jpg",
            "gap1969_forever_skinny_jeans3.jpg",
            "gap1969_forever_skinny_jeans4.jpg"
        ],
        "measurement": "Measurements (approximate): Waist: 26\"-27\", Hips: 35\", Front rise 8\"-8.5\", back rise 12\", inseam 31\".",
        "originalRetail": 79,
        "itemNumber": "B0120",
        "name": "Gap 1969 Forever Skinny Jeans",
        "mainCategory": "Women's Clothing",
        "subCategory": "Pants/Jeans",
        "id": 20
    },
    {
        "brand": "Lucky Brand",
        "condition": "NWT",
        "price": 25,
        "size": "30x34",
        "color": "Nirvana (medium)Wash",
        "material": "98% Cotton/2% Spandex",
        "description": "Classic 5-pocket Lucky Brand 361 vintage straight leg jeans. Beautiful distressed medium wash. Button-closure, zip-fly. Mid rise.  ",
        "pictures": [
            "lucky_brand_361vintage_straight_mens_jeans.jpg",
            "lucky_brand_361vintage_straight_mens_jeans2.jpg",
            "lucky_brand_361vintage_straight_mens_jeans3.jpg",
            "lucky_brand_361vintage_straight_mens_jeans4.jpg"
        ],
        "measurement": "Measurements (approximate): Waist: 30 (although size tag says it's 30\" but I measured it's more like in between 30\"-32\"), Hips: 39\"-40\"\",Front rise 9\", back rise 11.5\", inseam 34\".",
        "originalRetail": 99,
        "itemNumber": "B0121",
        "name": "Lucky Brand Men's 361 Vintage Straight Leg Distressed Jeans",
        "mainCategory": "Men's/Boy's Clothing",
        "subCategory": "Pants/Jeans",
        "id": 21
    },
    {
        "brand": "Southpole",
        "condition": "Pre-owned",
        "price": 5,
        "size": "3",
        "color": "Black Wash",
        "material": "96% Cotton/4% Lycra",
        "description": "Stunning black wash  flare leg jeans adorned with sparkling rhinestones. Worn only a handful of times. Some rhinestones in the back are missing (I guess I'm not the type that sits still) but otherwise in excellent condition.",
        "pictures": [
            "southpole_womens_flareleg_jeans.jpg",
            "southpole_womens_flareleg_jeans2.jpg",
            "southpole_womens_flareleg_jeans3.jpg",
            "southpole_womens_flareleg_jeans4.jpg"
        ],
        "measurement": "Measurements (approximate): Waist: 25\"-26\", Hips: 34\"-35\", Front rise 8\"-8.5\", back rise 11\", inseam 31.5\".",
        "originalRetail": null,
        "itemNumber": "B0122",
        "name": "Southpole Women's Flare Leg Fancy Rhinestone Jeans",
        "mainCategory": "Women's Clothing",
        "subCategory": "Pants/Jeans",
        "id": 22
    },
    {
        "brand": "Ecko Red",
        "condition": "Pre-owned",
        "price": 5,
        "size": "5",
        "color": "Medium Wash",
        "material": "99% Cotton/1% Spancex",
        "description": "Unique pair of jean with braided details on both sides and in the back.  Barely worn in excellent condition. The braided portion is denim fabric actually woven together!",
        "pictures": [
            "ecko_red_womens_braided_side_jeans.jpg",
            "ecko_red_womens_braided_side_jeans2.jpg",
            "ecko_red_womens_braided_side_jeans3.jpg",
            "ecko_red_womens_braided_side_jeans4.jpg"
        ],
        "measurement": "Measurements (approximate): Waist: 26\"-27\", Hips: 35\"-36\", Front rise 7.5\"-8\", back rise 11.5\", inseam 31\".",
        "originalRetail": null,
        "itemNumber": "B0123",
        "name": "Ecko Red Women's Braided Side Jeans",
        "mainCategory": "Women's Clothing",
        "subCategory": "Pants/Jeans",
        "id": 23
    },
    {
        "brand": "Lands' End",
        "condition": "NWOT",
        "price": 5,
        "size": "L",
        "color": "Pink",
        "material": "80% Nylon/20% Spandex",
        "description": "Lands' End girl's pink rash guard with long sleeve and mock neck.",
        "pictures": [
            "lands_end_girls_pink_LS_rashguards_L.jpg",
            "lands_end_girls_pink_LS_rashguards_L2.jpg"
        ],
        "measurement": "",
        "originalRetail": null,
        "itemNumber": "B0124",
        "name": "Lands' End Girl's Long Sleeve Rash Guards",
        "mainCategory": "Girl's Clothing",
        "subCategory": "Swimwear",
        "id": 24
    },
    {
        "brand": "Lands' End",
        "condition": "NWOT",
        "price": 5,
        "size": "XL 18H-20H Husky",
        "color": "Blue Shark",
        "material": "80% Nylon/20% Spandex",
        "description": "lands_end_boys_shark_rashguards.jpg|lands_end_boys_shark_rashguards2.jpg",
        "pictures": [
            "lands_end_boys_shark_rashguards.jpg",
            "lands_end_boys_shark_rashguards2.jpg"
        ],
        "measurement": "",
        "originalRetail": null,
        "itemNumber": "B0125",
        "name": "Lands' End Boy's Long Sleeve Rash Guards",
        "mainCategory": "Men's/Boy's Clothing",
        "subCategory": "Swimwear",
        "id": 25
    },
    {
        "brand": "Halogen by Nordstrom",
        "condition": "NWT",
        "price": 12,
        "size": "S",
        "color": "Green Oasis",
        "material": "82% Viscose/18% Nylon",
        "description": "Refreshing apple green classis button front crewneck cardigan.  Silky, soft, light-weight and super versatile.",
        "pictures": [
            "halogen_crewneck_cardigan_green_SP.jpg",
            "halogen_crewneck_cardigan_green_SP2.jpg",
            "halogen_crewneck_cardigan_green_SP3.jpg"
        ],
        "measurement": "",
        "originalRetail": 59,
        "itemNumber": "B0126",
        "name": "Halogen Crew Neck Cardigan",
        "mainCategory": "Women's Clothing",
        "subCategory": "Top",
        "id": 26
    },
    {
        "brand": "Mimi Chica from Nordstrom",
        "condition": "NWT",
        "price": 10,
        "size": "S Junior",
        "color": "Ivory Floral",
        "material": "Outer layer: 100% Polyester/Lining: 100% polyester",
        "description": "Airy and dreamy, Mimi Chica floral print chiffon Palazzo pants features light-weight flowy fabric, wide leg and elastic waist in the back.",
        "pictures": [
            "mimi_chica_print_chiffon_pants.jpg",
            "mimi_chica_print_chiffon_pants2.jpg",
            "mimi_chica_print_chiffon_pants3.jpg",
            "mimi_chica_print_chiffon_pants4.jpg",
            "mimi_chica_print_chiffon_pants5.jpg"
        ],
        "measurement": "Measurements (approximate): Waist: 28\" (without any stretch), Hips: 36\"-38\", Rise 8.5\"-8\", inseam 30.5\".",
        "originalRetail": 38,
        "itemNumber": "B0127",
        "name": "Mimi Chica Print Chiffon Palazzo Pants",
        "mainCategory": "Women's Clothing",
        "subCategory": "Pants/Jeans",
        "id": 27
    },
    {
        "brand": "Pleione from Nordstrom",
        "condition": "Pre-owned",
        "price": 5,
        "size": "S",
        "color": "Royal Blue",
        "material": "95% Rayon/5% Spandex, Trim: 100% Polyester",
        "description": "Beautiful royal blue knit top with zipper déco on top and black chiffon trim at bottom. Yes, the zippers are functional but I don't see the point of unzipping them. Excellent condition.",
        "pictures": [
            "pleione_chiffon_trim_blue_top.jpg",
            "pleione_chiffon_trim_blue_top2.jpg",
            "pleione_chiffon_trim_blue_top3.jpg",
            "pleione_chiffon_trim_blue_top4.jpg"
        ],
        "measurement": "",
        "originalRetail": null,
        "itemNumber": "B0128",
        "name": "Pleione Chiffon Trim Knit Top",
        "mainCategory": "Women's Clothing",
        "subCategory": "Top",
        "id": 28
    },
    {
        "brand": "Morgan De Toi",
        "condition": "Pre-owned",
        "price": 8,
        "size": "M",
        "color": "Multi-color",
        "material": "94.5% Polyester/5.5% Spandex",
        "description": "I got this gorgeous top during a trip. It's a French brand.  The soft fabric hugs the body just right and the design is super flattering.  It runs small, I was an S but had to get an M. Too bad I never got the chance to wear it as often as I'd wished. It's like new.",
        "pictures": [
            "morgan_de_toi_floral_top.jpg",
            "morgan_de_toi_floral_top2.jpg",
            "morgan_de_toi_floral_top3.jpg",
            "morgan_de_toi_floral_top4.jpg"
        ],
        "measurement": "",
        "originalRetail": null,
        "itemNumber": "B0129",
        "name": "Morgan De Toi Floral Chiffon Cinched Top",
        "mainCategory": "Women's Clothing",
        "subCategory": "Top",
        "id": 29
    },
    {
        "brand": "Ocean Uglies by Dolfin",
        "condition": "NWT",
        "price": 5,
        "size": "M",
        "color": "Coral Pink/Turquoise/Floral",
        "material": "86% Polyester/14% Spandex",
        "description": "Girl's short sleeve rash guards from the Ocean Uglies by Dolfin",
        "pictures": [
            "dolfin_ocean_uglies_girls_rash_guards.jpg",
            "dolfin_ocean_uglies_girls_rash_guards2.jpg"
        ],
        "measurement": "",
        "originalRetail": null,
        "itemNumber": "B0130",
        "name": "Dolfin Ocean Uglies Girl's Short Sleeves Rash Guards",
        "mainCategory": "Girl's Clothing",
        "subCategory": "Swimwear",
        "id": 30
    },
    {
        "brand": "Marika",
        "condition": "NWT",
        "price": 10,
        "size": "M",
        "color": "White",
        "material": "Body: 83% Nylon/17% Spandex, Cup Lining: 90% Polyester/10% Spandex",
        "description": "Marika High Impact sports bra is recommended for activities such as running, tennis, or team sports. Loaded with extra features such as CoolMax lining, padded cups & straps for extra comfort & coverage and easy zip front closure.",
        "pictures": [
            "marika_high_impact_sports_bra.jpg",
            "marika_high_impact_sports_bra2.jpg"
        ],
        "measurement": "",
        "originalRetail": 55,
        "itemNumber": "B0131",
        "name": "Marika High Impact Zip Front Sports Bra",
        "mainCategory": "Women's Clothing",
        "subCategory": "Other",
        "id": 31
    },
    {
        "brand": "Home by Target",
        "condition": "NWT",
        "price": 12,
        "size": "",
        "color": "Green Floral",
        "material": "100% Cotton",
        "description": "Neutral green floral 100% cotton fabric shower curtain turns your shower into an oasis. Nice weight and feels heavy duty. Standard top (with loops in the fabric for hanging). Machine wash, tumble dry",
        "pictures": [
            "shower_curtain_home_green_floral_NWT.jpg",
            "shower_curtain_home_green_floral_NWT2.jpg",
            "shower_curtain_home_green_floral_NWT3.jpg"
        ],
        "measurement": "72\"x72\" (182.8cmx182.8xm)",
        "originalRetail": null,
        "itemNumber": "B0132",
        "name": "Target Home Collection 100% Cotton Green Floral Shower Curtain",
        "mainCategory": "Household Items",
        "subCategory": "",
        "id": 32
    },
    {
        "brand": "Home by Target",
        "condition": "NWOT",
        "price": 10,
        "size": "",
        "color": "Green Floral",
        "material": "100% cotton",
        "description": "Neutral green floral 100% cotton fabric shower curtain turns your shower into an oasis. Nice weight and feels heavy duty. This is the exact same item as B0132, only this one does not have the package but it's still brand new. Standard top (with loops in the fabric for hanging). Machine wash, tumble dry",
        "pictures": [
            "shower_curtain_home_green_floral1.jpg",
            "shower_curtain_home_green_floral2.jpg",
            "shower_curtain_home_green_floral3.jpg"
        ],
        "measurement": "72\"x72\" (182.8cmx182.8xm)",
        "originalRetail": null,
        "itemNumber": "B0133",
        "name": "Target Home Collection 100% Cotton Green Floral Shower Curtain",
        "mainCategory": "Household Items",
        "subCategory": "",
        "id": 33
    },
    {
        "brand": "Tiddliwinks from Target",
        "condition": "NWT",
        "price": 12,
        "size": "",
        "color": "Whale/Brown Polka Dot",
        "material": "100% Polyester",
        "description": "Darling shower curtain with brown polka dots on top and cute whale design at the bottom.  The neutral color and the design makes it perfect for both kids, teens or even adults!  It has a weighted bottom& grommet top.",
        "pictures": [
            "shower_curtain_tiddliwinks_whale.jpg",
            "shower_curtain_tiddliwinks_whale2.jpg",
            "shower_curtain_tiddliwinks_whale3.jpg"
        ],
        "measurement": "71\"x71\" (180cmx180cm)",
        "originalRetail": null,
        "itemNumber": "B0134",
        "name": "Tiddliwinks Whale & Brown Polka Dot Shower Curtain",
        "mainCategory": "Household Items",
        "subCategory": "",
        "id": 34
    },
    {
        "brand": "Circo from Target",
        "condition": "NWT",
        "price": 8,
        "size": "",
        "color": "Sealife/Ocean/Clear",
        "material": "EVA",
        "description": "Every kid will love to shower with this super fun and colorful shower curtain filled with sealife print such as fish, shark, octopus, whale, seahorse, seastar, crab, submarine and even snail? This product does not contain PVC & only need to be wiped clean. Grommet top.",
        "pictures": [
            "shower_curtain_circo_ocean.jpg",
            "shower_curtain_circo_ocean2.jpg",
            "shower_curtain_circo_ocean3.jpg"
        ],
        "measurement": "70\"x72\" (177.8cmx182.8cm)",
        "originalRetail": null,
        "itemNumber": "B0135",
        "name": "Circo Sealife Ocean Theme Shower Curtain",
        "mainCategory": "Household Items",
        "subCategory": "",
        "id": 35
    },
    {
        "brand": "DYR from Target",
        "condition": "NWT",
        "price": 10,
        "size": "",
        "color": "Blue/Ocean/Sealife",
        "material": "100% cotton",
        "description": "This fun filled ocean theme fabric shower curtain feature sequin octopus and crabs with red rehinestone eyes! Standard top (with loops in the fabric for hanging). Hand wash cold. Hang dry",
        "pictures": [
            "shower_curtain_dyr_ocean.jpg",
            "shower_curtain_dyr_ocean2.jpg",
            "shower_curtain_dyr_ocean3.jpg"
        ],
        "measurement": "72\"x72\" (182.8cmx182.8xm)",
        "originalRetail": null,
        "itemNumber": "B0136",
        "name": "DYR (Do Your Room) 100% Cotton Ocean Theme Shower Curtain",
        "mainCategory": "Household Items",
        "subCategory": "",
        "id": 36
    }
]
};
export default data;
