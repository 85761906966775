import React from "react";
import Product from "./Product";
import Container from "react-bootstrap/Container";
import { Row, Col } from "react-bootstrap";
// import Navigation from "./Navigation";
import { Inventory } from "../utils/Inventory";
import LeftNav from "./LeftNav";

export default function Main(props) {
  const currentCart = props.cart;
  const inventory = new Inventory();

  // TODO should show feature items only, random for now.
  const products = inventory.randomProducts(10);

  return (
    <Container fluid className="px-0">
      {/* <Navigation products={products} /> */}
      <Row>
        <Col md={2}>
          <LeftNav />
        </Col>
        <Col md={10}>
          <Container fluid className="feature-title">
            Feature items from the past week... nice!
          </Container>
          <Container fluid className="main-page">
            <Row md={5}>
              {products.map((product) => (
                <Product
                  key={product.id}
                  product={product}
                  cart={currentCart}
                ></Product>
              ))}
            </Row>
          </Container>
        </Col>
      </Row>
    </Container>
  );
}
