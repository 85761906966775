import { Container } from "react-bootstrap";
import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

import CartItem from "./CartItem";
import { ItemUtils } from "../utils/ItemUtils";

export default function Cart(props) {
  const currentCart = props.cart;
  const setCount = props.setCount;

  const [cartItems, setCartItems] = useState(currentCart.getItems());
  // console.log("cartItems=" + Array.from(cartItems));
  // console.log("exists=" + currentCart.itemExists(1));

  const onRemove = (id) => {
    const qty = currentCart.getItemCount(id);
    if (qty > 0) {
      currentCart.removeItem(id);
      setCartItems(currentCart.getItems());
      setCount(currentCart.getCount());
    }
  };

  function CartMessage() {
    const numItems = currentCart.getCount();
    const message =
      numItems > 0
        ? `You have ${numItems} item(s) in your shopping cart.`
        : `Your shopping cart is currently empty.`;
    return <div className="cart-message">{message}</div>;
  }

  function Total() {
    if (currentCart.getCount() === 0) {
      return "";
    }
    const totalPrice = currentCart.getTotalPrice();
    const formattedTotalPrice = ItemUtils.formatPrice(totalPrice);
    return (
      <Row>
        <Col sm={10}></Col>
        <Col sm={2}>
          <div className="cart-total">Total: {formattedTotalPrice}</div>
        </Col>
      </Row>
    );
  }

  function CartHeader() {
    if (currentCart.getCount() === 0) {
      return "";
    }
    return (
      <div>
        <Row>
          <Col sm={10}></Col>
          <Col sm={2} className="cart-header-price">
            Price
          </Col>
        </Row>
        <hr />
      </div>
    );
  }

  function Checkout() {
    if (currentCart.getCount() === 0) {
      return "";
    }
    return (
      <Link to="/checkout">
        <Button>Checkout</Button>
      </Link>
    );
  }

  return (
    <Container className="cart-block">
      <CartMessage />
      <CartHeader />
      {[...cartItems].map((id, index) => (
        <div key={id}>
          <CartItem
            id={id}
            itemCount={currentCart.getItemCount(id)}
            onRemove={onRemove}
            index={index + 1}
          />
          <hr />
        </div>
      ))}
      <Total />
      <Checkout />
    </Container>
  );
}
