import data from "../data2";

export class Inventory {
  getProducts() {
    return data.products;
  }

  getItem(itemId /* int */) {
    return data.products.find((x) => x.id === itemId);
  }

  getProductsWithFilter(mainCategory, subCategory) {
    let products = data.products;
    if (mainCategory) {
      products = products.filter((x) => mainCategory === x.mainCategory);
    }
    if (subCategory && subCategory !== "All") {
      products = products.filter((x) => subCategory === x.subCategory);
    }
    return products;
  }

  compareSubCategory(c1, c2) {
    const orders = [
      "Top",
      "Sweater",
      "Jacket/Outerwear",
      "Sweater/Dress",
      "Pants/Jeans",
      "Swimwear",
      "Other",
    ];
    const i1 = orders.findIndex((x) => x === c1);
    const i2 = orders.findIndex((x) => x === c2);
    const bottom = 99999;
    const j1 = i1 >= 0 ? i1 : bottom;
    const j2 = i2 >= 0 ? i2 : bottom;
    if (j1 < j2) {
      return -1;
    } else if (j1 > j2) {
      return 1;
    } else {
      return 0;
    }
  }

  getSubCategories(mainCategory) {
    const products = data.products.filter(
      (x) => mainCategory === x.mainCategory
    );
    const subCategories = new Set(products.map((x) => x.subCategory));
    const subCategoriesList = Array.from(subCategories).sort(
      this.compareSubCategory
    );
    return subCategoriesList;
  }

  getCategoriesAsSet() {
    return new Set(data.products.map((x) => x.mainCategory));
  }

  randomProducts(num) {
    const randomList = data.products.sort(() => Math.random() - 0.5);
    return randomList.slice(0, num);
  }
}
