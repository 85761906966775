import React from "react";

export default function Price(props) {
  const price = parseFloat(props.price);
  const formattedPrice = Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: 0,
  }).format(price);
  return (
    <div className={props.className}>
      {props.label ? (
        <span className={props.labelClassName}>{props.label}</span>
      ) : (
        ""
      )}
      {props.label ? ":" : ""}
      {formattedPrice}
    </div>
  );
}
