import React, { useState, useRef } from "react";
import emailjs from "emailjs-com";
import { Row, Col, Button, Form, Modal } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import Price from "./Price";
import { Inventory } from "../utils/Inventory";
import moment from "moment";

export default function OrderForm(props) {
  const currentCart = props.cart;
  const setCount = props.setCount;
  const inventory = new Inventory();
  const form = useRef();
  const history = useHistory();

  const [errors, setErrors] = useState({});
  const [validated, setValidated] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);

  function sendEmail(e) {
    e.preventDefault();

    checkForm();
    if (!validated) {
      alert("Please enter form and then click the order button");
      return;
    }

    // Update the hidden item fields
    updateItems();

    emailjs
      .sendForm(
        "service_8vxhfmt",
        "template_tuhq6qs",
        e.target,
        "user_hC5vrjiaDhxctf0dDSRO9"
      )
      .then(
        (result) => {
          setShowSuccess(true);
        },
        (error) => {
          setShowError(true);
        }
      );
  }

  function checkForm() {
    const newErrors = {};
    if (
      form.current.firstName.value.length > 0 &&
      form.current.firstName.value.length < 2
    ) {
      newErrors.firstName = "Invalid first name";
    }
    if (
      form.current.lastName.value.length > 0 &&
      form.current.lastName.value.length < 2
    ) {
      newErrors.lastName = "Invalid last name";
    }
    const re = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    if (
      form.current.emailAddress.value.length > 0 &&
      !re.test(form.current.emailAddress.value)
    ) {
      newErrors.emailAddress = "Invalid email address";
    }
    setErrors(newErrors);

    const allGood = Object.keys(newErrors).length === 0;
    setValidated(allGood);
  }

  function updateItems() {
    return Array.from(currentCart.getItems()).forEach((id, index) => {
      const item = inventory.getItem(id);
      const component = "item" + (index + 1);
      const value = `${index + 1}: ${item.itemNumber}: ${item.name} ($${
        item.price
      })`;
      form.current[component].value = value;
    });
  }

  function closeSuccessDialog() {
    currentCart.clear();
    setCount(0);
    setShowSuccess(false);
    history.push("/"); // back to home page
  }

  const closeErrorDialog = () => {
    setShowError(false);
  };

  return (
    <div>
      <div className="order-form-message">
        Please enter the following form to reserve your items:
      </div>
      <Form ref={form} className="order-form" onSubmit={sendEmail}>
        <Form.Group className="mb-3" controlId="orderId">
          <Form.Label>Order ID</Form.Label>
          <Form.Control
            type="text"
            value={moment().format("YYMMDDhhmmss")}
            name="orderId"
            readOnly
          ></Form.Control>
        </Form.Group>

        <Form.Group className="mb-3" controlId="firstName">
          <Form.Label>
            First Name{" "}
            <span className="mandatory-field-label-indicator">*</span>
          </Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter first name"
            name="firstName"
            isInvalid={!!errors.firstName}
            onChange={checkForm}
          ></Form.Control>
          <Form.Control.Feedback type="invalid">
            {errors.firstName}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-4" controlId="lastName">
          <Form.Label>
            Last Name <span className="mandatory-field-label-indicator">*</span>
          </Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter last name"
            name="lastName"
            isInvalid={!!errors.lastName}
            onChange={checkForm}
          ></Form.Control>
          <Form.Control.Feedback type="invalid">
            {errors.lastName}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-4" controlId="emailAddress">
          <Form.Label>
            Email Address{" "}
            <span className="mandatory-field-label-indicator">*</span>
          </Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter email address"
            name="emailAddress"
            isInvalid={!!errors.emailAddress}
            onChange={checkForm}
          ></Form.Control>
          <Form.Control.Feedback type="invalid">
            {errors.emailAddress}
          </Form.Control.Feedback>
        </Form.Group>

        <Button variant="primary" type="submit" className="order-btn">
          Submit order now!
        </Button>

        {[...Array(30).keys()].map((index) => {
          const id = `item${index + 1}`;
          return <Form.Control type="text" name={id} id={id} key={id} hidden />;
        })}
      </Form>

      <hr />
      <div className="order-form-message">
        Here's a quick review of your shopping cart:
      </div>
      <div className="order-form-items">
        {Array.from(currentCart.getItems()).map((id, index) => {
          const item = inventory.getItem(id);
          return (
            <div key={id}>
              <Row>
                <Col sm={1} className="text-right">
                  {index + 1}.{" "}
                </Col>
                <Col sm={4}>{item.name}</Col>
                <Col sm={2}>
                  <Price price={item.price} />
                </Col>
                <Col sm></Col>
              </Row>
            </div>
          );
        })}
      </div>
      <Price
        className="order-form-total-message bold"
        label="Your total cost"
        price={currentCart.getTotalPrice()}
      />
      <div className="order-form-total-message">
        No payment is due at this time.
      </div>

      {/* Popup dialog after successful order */}
      <Modal show={showSuccess} onHide={closeSuccessDialog}>
        <Modal.Header closeButton>
          <Modal.Title>Success!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Thank you, your order has been sent! We will contact you via email
          about the order soon. You will be directed to the home page after
          clicking the Close button.
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={closeSuccessDialog}>Close</Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showError} onHide={closeErrorDialog}>
        <Modal.Header closeButton>
          <Modal.Title>Error</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Sorry, there was an error submitting your order.
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={closeErrorDialog}>Close</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
