export class ItemUtils {
  // Design note: normally, we should have designed an `Item` class and put this
  // method there. However, since the items are just plain-old JS objects (POJSO),
  // we just define define static utility methods instead.
  static getImagePath(item, num = 0) {
    return "/pictures/" + item.pictures[num];
  }

  static getThumbnailImagePath(item, num = 0) {
    return "/thumbnails/" + item.pictures[num];
  }

  // Return number of images for the item
  static getImagesCount(item) {
    return item.pictures.length;
  }

  static formatPrice(price) {
    return Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      maximumFractionDigits: 0,
    }).format(price);
  }
}
