import React from "react";
import Image from "react-bootstrap/Image";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import Price from "./Price";

export default function Product(props) {
  const product = props.product;
  const currentCart = props.cart;
  const thumbnail = "pictures/" + product.pictures[0];
  const addString = currentCart.itemExists(product.id) ? "(added to cart)" : "";
  return (
    <Link className="product-link" to={`/products/${product.id}`}>
      <Col>
        <div>
          <Image className="small" src={thumbnail} alt={product.name} />
          <div className="product-item-name">{product.name}</div>
          <div className="product-item-in-cart">{addString}</div>
          <Price className="product-item-price" price={product.price} />
        </div>
      </Col>
    </Link>
  );
}
