import React from "react";
import Container from "react-bootstrap/Container";
import { Row, Col } from "react-bootstrap";
import LeftNav from "./LeftNav";

export default function Contact(props) {
  return (
    <Container fluid className="px-0">
      <Row>
        <Col md={2}>
          <LeftNav />
        </Col>
        <Col md={10}>
          <Container fluid className="main-page">
            Contact us here!
          </Container>
        </Col>
      </Row>
    </Container>
  );
}
