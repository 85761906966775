import { Container } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import React, { useState } from "react";

import Header from "./components/Header";
import Main from "./components/Main";
import Cart from "./components/Cart";
import Contact from "./components/Contact";
import FAQ from "./components/FAQ";
import OrderForm from "./components/OrderForm";
import ProductList from "./components/ProductList";
import ProductPage from "./components/ProductPage";
import { CartSession } from "./utils/CartSession";
import { Inventory } from "./utils/Inventory";

function App() {
  const products = new Inventory().getProductsWithFilter(
    new Set(["Top"]),
    false
  );
  const currentCart = new CartSession();
  const [count, setCount] = useState(currentCart.getCount());

  return (
    <Container fluid className="App">
      <Router>
        <Header count={count} setCount={setCount} />
        <Switch>
          <Route
            exact
            path="/"
            component={() => <Main cart={currentCart} products={products} />}
          />
          <Route
            exact
            path="/cart"
            component={() => <Cart cart={currentCart} setCount={setCount} />}
          />
          <Route
            exact
            path="/products/:id"
            component={() => (
              <ProductPage cart={currentCart} setCount={setCount} />
            )}
          />
          <Route
            exact
            path="/product_list"
            component={() => (
              <ProductList cart={currentCart} setCount={setCount} />
            )}
          />
          <Route
            exact
            path="/contact"
            component={() => <Contact cart={currentCart} setCount={setCount} />}
          />
          <Route
            exact
            path="/faq"
            component={() => <FAQ cart={currentCart} setCount={setCount} />}
          />
          <Route
            exact
            path="/checkout"
            component={() => (
              <OrderForm cart={currentCart} setCount={setCount} />
            )}
          />
        </Switch>
      </Router>
    </Container>
  );
}

export default App;
