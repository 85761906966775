import { Row, Col } from "react-bootstrap";
// import data from "../data2";
import Image from "react-bootstrap/Image";
import React from "react";
import { ItemUtils } from "../utils/ItemUtils";
import { Inventory } from "../utils/Inventory";
import Price from "./Price";

export default function CartItem(props) {
  const inventory = new Inventory();
  const id = props.id;
  const onRemove = props.onRemove;
  const item = inventory.getItem(id);

  return (
    <Row>
      <Col sm={1}>{props.index}.</Col>
      <Col sm={1}>
        <div className="cart-item-number">{item.itemNumber}</div>
      </Col>
      <Col sm={1}>
        <Image
          className="small img-fluid"
          src={ItemUtils.getThumbnailImagePath(item, 0)}
        ></Image>
      </Col>
      <Col sm={7}>
        <div className="cart-item-name">{item.name}</div>
        <div className="cart-delete-link" onClick={() => onRemove(item.id)}>
          Delete
        </div>
      </Col>
      <Col sm={2}>
        <div className="align-items-right">
          <Price className="cart-item-price" price={item.price} />
        </div>
      </Col>
    </Row>
  );
}
