import { Container, Row, Col, Button, Modal } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import Image from "react-bootstrap/Image";
import { useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { Inventory } from "../utils/Inventory";
import { ItemUtils } from "../utils/ItemUtils";
import LeftNav from "./LeftNav";

export default function ProductPage(props) {
  const { id } = useParams();
  const currentCart = props.cart;
  const inventory = new Inventory();
  const item = inventory.getItem(parseInt(id));
  const setCount = props.setCount;
  const [isInCart, setIsInCart] = useState(currentCart.itemExists(item.id));
  const [showingImageViewer, setShowingImageViewer] = useState(false);
  const [currentImage, setCurrentImage] = useState(-1);

  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const setDimension = () => {
    setScreenSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };
  useEffect(() => {
    window.addEventListener("resize", setDimension);
    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize]);

  useEffect(() => {
    // console.log("currentImage = " + currentImage);
    if (currentImage >= 0) {
      setShowingImageViewer(true);
    }
  }, [currentImage]);

  function addItemToCart() {
    currentCart.addItem(id);
    setCount(currentCart.getCount());
    setIsInCart(true);
  }

  // Display an action button for this product item.
  // If the product is already in cart, then display a disabled button.
  function ActionButton() {
    if (!isInCart) {
      return (
        <div>
          <Button onClick={addItemToCart}>Add to Cart</Button>
        </div>
      );
    } else {
      return (
        <div>
          <Button disabled>Added to cart</Button>
        </div>
      );
    }
  }

  function showImageViewer(imageNum) {
    return () => {
      setCurrentImage(imageNum);
    };
  }

  function closeImageViewer() {
    setShowingImageViewer(false);
    setCurrentImage(-1); // make sure that it doesn't pop automatically
  }

  function ThumbnailImage(props) {
    const imageNum = parseInt(props.imageNum);
    if (imageNum < ItemUtils.getImagesCount(item)) {
      return (
        <Image
          className={`big img-fluid clickable ${props.className}`}
          src={ItemUtils.getThumbnailImagePath(item, imageNum)}
          onClick={showImageViewer(imageNum)}
        />
      );
    } else {
      return "";
    }
  }

  function LargeImage(props) {
    const imageNum = parseInt(props.imageNum);
    if (imageNum < ItemUtils.getImagesCount(item)) {
      return (
        <Image
          src={ItemUtils.getImagePath(item, imageNum)}
          className="image-viewer-image"
        />
      );
    } else {
      return "";
    }
  }

  function ItemField(props) {
    return (
      <div className="product-item-field">
        <Row>
          <Col sm={4} className="product-item-field-label">
            {props.label}
          </Col>
          <Col sm="auto" className="product-item-field-value">
            {props.value}
          </Col>
        </Row>
      </div>
    );
  }

  // function imageViewerThumbnailClass(imageNum) {
  //   return currentImage === imageNum ? "selected-image-in-viewer" : "";
  // }
  const measurementInfo = item.measurement ? (
    <ItemField label="Measurement" value={item.measurement}></ItemField>
  ) : (
    ""
  );

  return (
    <Row>
      <Col md={2}>
        <LeftNav />
      </Col>
      <Col md={10}>
        <Container className="product-block">
          <Row>
            <div className="product-page-name">{item.name}</div>
          </Row>
          <Row>
            <Col>
              <Row>
                <Col md="auto">
                  <Image
                    className="big img-fluid clickable"
                    src={ItemUtils.getImagePath(item, 0)}
                    onClick={showImageViewer(0)}
                  />
                </Col>
              </Row>
              <Row>
                <Col md="auto">
                  <ThumbnailImage imageNum="1" />
                </Col>
                <Col md="auto">
                  <ThumbnailImage imageNum="2" />
                </Col>
                <Col md="auto">
                  <ThumbnailImage imageNum="3" />
                </Col>
              </Row>
            </Col>
            <Col className="product-page-desc">
              <div className="largerFont">
                <div>{item.description}</div>
                <ItemField label="Item #" value={item.itemNumber}></ItemField>
                <ItemField label="Brand" value={item.brand}></ItemField>
                <ItemField
                  label="Main Category"
                  value={item.mainCategory}
                ></ItemField>
                <ItemField
                  label="Sub Category"
                  value={item.subCategory}
                ></ItemField>
                <ItemField label="Condition" value={item.condition}></ItemField>
                <ItemField label="Size" value={item.size}></ItemField>
                <ItemField label="Color" value={item.color}></ItemField>
                <ItemField label="Material" value={item.material}></ItemField>
                {measurementInfo}
                <ItemField
                  label="Price"
                  value={ItemUtils.formatPrice(item.price)}
                ></ItemField>
                <p></p>
              </div>
              <ActionButton id={item.id} />
            </Col>
          </Row>
          <Modal
            show={showingImageViewer}
            onHide={closeImageViewer}
            dialogClassName="image-viewer-dialog"
            // size="lg"
          >
            <Modal.Header closeButton>
              <Modal.Title>{item.name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <LargeImage id="ImageView" imageNum={currentImage} />
              </div>
            </Modal.Body>
          </Modal>
        </Container>
      </Col>
    </Row>
  );
}
