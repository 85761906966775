import React, { useState } from "react";
import Product from "./Product";
import Container from "react-bootstrap/Container";
import { Row, Col, Tabs, Tab } from "react-bootstrap";
import { Inventory } from "../utils/Inventory";
import LeftNav from "./LeftNav";

const categoryMapper = {
  womens: "Women's Clothing",
  mens: "Men's/Boy's Clothing",
  girls: "Girl's Clothing",
  household: "Household Items",
};

function mapCategoryFromHash(hash) {
  const mainCategory = hash.split("#")[1];
  // const categories = hash.split("#")[1];
  // const [main, sub] = categories.split("|");
  // return [categoryMapper[main], categoryMapper[sub]];
  return categoryMapper[mainCategory];
}

export default function ProductList(props) {
  const currentCart = props.cart;
  const inventory = new Inventory();
  const hash = window.location.hash; // eg. #{main_category}
  const mainCategory = mapCategoryFromHash(hash);
  const [subCategory, setSubCategory] = useState("All");

  const products =
    hash.length > 0
      ? inventory.getProductsWithFilter(mainCategory, subCategory)
      : inventory.getProducts();

  // const unique_categories = new Set(products.map((x) => x.subCategory));
  let avail_categories = inventory.getSubCategories(mainCategory);
  avail_categories.unshift("All"); // prepend

  if (!avail_categories.find((x) => x === subCategory)) {
    setSubCategory("All");
  }

  return (
    <Container fluid className="px-0">
      <Row>
        <Col md={2}>
          <LeftNav />
        </Col>
        <Col md={10}>
          <Tabs
            className="category-nav"
            defaultActiveKey="All"
            activeKey={subCategory}
            onSelect={(k) => {
              console.log("Setting sub category = " + subCategory);
              setSubCategory(k);
            }}
          >
            {avail_categories.map((x) => (
              <Tab eventKey={x} title={x} key={x}>
                <Container fluid className="main-page">
                  <Row md={5}>
                    {products.map((product) => (
                      <Product
                        key={product.id}
                        product={product}
                        cart={currentCart}
                      ></Product>
                    ))}
                  </Row>
                </Container>
              </Tab>
            ))}
          </Tabs>
        </Col>
      </Row>
    </Container>
  );
}
