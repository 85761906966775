import { Inventory } from "./Inventory";

/**
 * CartSession
 * Keep track of the current shopping cart.
 *
 * Design note:
 * The data structure is very simple. The entire cart is just an array of
 * item id's. If the same item is added ot the cart twice, then the item's
 * id appears twice in the array.
 */
export class CartSession {
  // ===== Private methods =====

  // The follwoing are private methods, what we use to represent the cart
  // in memory and how we keep track of the data is implementation detail
  // and not exposed to clients.

  // Return the raw item id's array.
  _getItemIds() {
    const items = this._readFromStorage();
    return items && items.length > 0 ? JSON.parse(items) : [];
  }

  _readFromStorage() {
    const value = window.sessionStorage.getItem("cart");
    // console.log("Read from storage: " + value);
    return value;
  }

  _writeToStorage(itemIds) {
    // console.log("Writing storage: " + itemIds);
    const value = JSON.stringify(itemIds);
    window.sessionStorage.setItem("cart", value);
  }

  // ===== Public methods =====

  // Return true if the item exists in the cart
  itemExists(id /* int */) {
    // return this._getItemIds().filter((x) => x === id).length > 0;
    // console.log("itemExists: typeof = " + typeof id);
    // console.log("itemsSize = " + this.getItems().size);
    // console.log("items = " + Array.from(this.getItems()));
    // console.log("id = " + id);
    return this.getItems().has(parseInt(id));
  }

  // Return qty for the specified item
  getItemCount(id /* int */) {
    return this._getItemIds().filter((x) => x === parseInt(id)).length;
  }

  // Return total number of items in the cart
  getCount() {
    return new Set(this._getItemIds()).size;
  }

  // Returns an object with key = itemId, value = qty
  getItemCounts() {
    let itemSet = new Set(this._getItemIds());
    let obj = {};
    for (let itemId of itemSet.values()) {
      obj[itemId] = this.getItemCount(itemId);
    }
    return obj;
  }

  // Return all items in the cart as a Set
  getItems() {
    return new Set(this._getItemIds());
  }

  // Add an item to the cart.
  addItem(id /* int */) {
    let itemIds = this._getItemIds();
    itemIds.push(parseInt(id));
    this._writeToStorage(itemIds);
  }

  // Remove an item from the cart.
  removeItem(id /* int */) {
    const updatedItemIds = this._getItemIds().filter((x) => x !== parseInt(id));
    this._writeToStorage(updatedItemIds);
  }

  getTotalPrice() {
    const itemIds = this._getItemIds();
    const inventory = new Inventory();
    const total = itemIds
      .map((id) => inventory.getItem(id).price)
      .reduce((a, b) => a + b, 0);
    return total;
  }

  clear() {
    this._writeToStorage([]);
  }
}
